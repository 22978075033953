@tailwind base;
@tailwind components;
@tailwind utilities;

.default-icon-button {
    @apply h-8 px-2 m-1 text-sm gap-1;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    pointer-events: all;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    background-color: black;
    box-shadow: 1px 1px 9px 1px rgba(0,0,0,0.30);
}
.default-icon-button:hover {
    box-shadow: inset -250px 0 0 0 black;
    transform: scale(1.1);
}

.default-button {
    @apply h-8 px-2 m-1 text-sm;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    pointer-events: all;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: black;
    box-shadow: 1px 1px 9px 1px rgba(0,0,0,0.30);
}

.dark .default-button {
    background-color: #252323;
    box-shadow: 1px 1px 9px 1px rgba(89, 87, 87, 0.3);
}

.default-button:hover {
    box-shadow: inset -250px 0 0 0 black;
    transform: scale(1.1);
}

.dark .default-button:hover {
    box-shadow: inset -250px 0 0 0 #2e2d2d;
    transform: scale(1.1);
}


.default-button:disabled {
    transform: none;
    box-shadow: none;
    background-color: #505050;
    color: #c4c1c1;
}
.default-button:disabled:hover {
    transform: none;
    box-shadow: none;
}

.joker-button {
    transition: all 0.2s ease-in-out;
}
.joker-button:hover {
    transform: scale(1.3);
}

.rules ol {
    counter-reset: item
}
.rules li {
    display: block
}
.rules li:before {
    content: counters(item, ".") ". ";
    counter-increment: item
}
li:before {
    @apply font-bold text-lg;
}

.flag:hover {
    transform: scale(1.4);
    transition: transform 0.2s;
}

.outlineImportant {
    outline: none !important;
}
* {
    outline: none !important;
}
